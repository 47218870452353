import { createApp } from "vue";
import { Picker, Field, Popup, Tab, Tabs } from "vant";
import { Input } from "ant-design-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./init";
import "../src/assets/iconfont/iconfont.css";
import "vant/lib/index.css";

createApp(App)
  .use(store)
  .use(router)
  .use(Input)
  .use(Picker)
  .use(Field)
  .use(Tab)
  .use(Tabs)
  .use(Popup)
  .mount("#app");

// 路由发生变化修改页面title
router.beforeEach((to, from, next) => {
  if (to.path == "/join") {
    document.title = "全国合作伙伴招募";
  }
  if (to.path == "/merjoin") {
    document.title = "邀请您成为收呗商户";
  }
  next();
});
