<template>
  <router-view />
</template>

<script setup>
import { onMounted } from "vue";
import { isMobile } from "./utils";

onMounted(() => {
  isMobile()
    ? (document.querySelector("body").style.minWidth = "none")
    : (document.querySelector("body").style.minWidth = "1200px");
});
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
.container {
  width: 1200px;
  margin: 0 auto;
}
</style>
